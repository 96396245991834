// @font-face {
//     font-family: 'av';
//     src: url('../public/assets/aeonik/AeonikProTRIAL-Light.otf');
//     font-weight: 300;
// }

// @font-face {
//     font-family: 'aeonik pro';
//     src: url('../public/assets/aeonik/AeonikProTRIAL-Regular.otf');
//     font-weight: 400;
// }

// @font-face {
//     font-family: 'aeonik pro';
//     src: url('../public/assets/aeonik/AeonikProTRIAL-Bold.otf');
//     font-weight: 700;
// }
@import "../public/styles/bootstrap";

@font-face {
  font-family: "avenir";
  font-weight: 300;
  src: local("avenir"),
    url("../public/fonts/Avenir/AvenirLTStd-Light.woff") format("woff");
}

@font-face {
  font-family: "avenir";
  font-weight: 400;
  src: local("avenir"),
    url("../public/fonts/Avenir/AvenirLTStd-Book.woff") format("woff");
}

@font-face {
  font-family: "avenir";
  font-weight: 500;
  src: local("avenir"),
    url("../public/fonts/Avenir/AvenirLTStd-Medium.woff") format("woff");
}

@font-face {
  font-family: "avenir";
  font-weight: 600;
  src: local("avenir"),
    url("../public/fonts/Avenir/AvenirLTStd-Heavy.woff") format("woff");
}

@font-face {
  font-family: "avenir";
  font-weight: 700;
  src: local("avenir"),
    url("../public/fonts/Avenir/AvenirLTStd-Black.woff") format("woff");
}

// variables
$primary: #0c4da2;
#root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}
html {
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  // font-size: 10px;
  scroll-behavior: smooth;

  &:has(.mobileNav-open) {
    overflow: hidden;
  }
}
.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: $primary;
  color: white;
}
body {
  color: #212121;

  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "avenir", sans-serif;
  font-weight: 500;
  /* font-size: 62.5%; */
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

// custom styling
.text-primary {
  color: $primary !important;
}

.ant-form-item-label {
  > label {
    display: inline-flex;
    flex-direction: row-reverse;

    &:after {
      display: none;
    }

    &.ant-form-item-required {
      display: inline-flex;
      flex-direction: row-reverse;
      gap: 3px;
    }
  }
}

@media (min-width: 1024px) {
  .top-left-wrapper {
    width: calc(100% - 200px);
    padding: 3px 0;
  }

  .top-right-wrapper {
    right: 0;
    position: absolute;
    top: 3px;
  }

  .button-wrap a,
  .button-wrap .dropdown button {
    font-size: 15px;
  }

  span.menu-img {
    padding: 5px 10px;
  }

  ul.single-menu-item.wsmenu-submenu {
    margin-right: 100%;
    top: 0;
    right: 0;
  }

  ul.top-nav-left li:after {
    content: "";
    width: 1px;
    height: 16px;
    position: absolute;
    background-color: #0c4da2;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .first-level > li > span {
    display: none;
  }

  .dropdown-menu.show {
    top: 20px !important;
  }
}

ul.top-nav-left.top-nav-left.d-flex li {
  list-style: none;
  padding: 3px 5px;
  position: relative;
}

ul.top-nav-left a {
  color: #0c4da2;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
}

ul.top-nav-left a:hover {
  color: #ed1c24;
}

ul.top-nav-left li.new {
  position: relative;
  padding-right: 38px !important;
}

ul.top-nav-left li.new span {
  font-size: 10px;
  animation-name: bgcolor;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  padding: 0px 5px;
  border-radius: 8px;
  color: #fff;
  border: none !important;
  position: absolute;
  top: 6px;
  right: 5px;
}

@keyframes bgcolor {
  0% {
    background-color: #0c4da2;
  }

  50% {
    background-color: #F0512D;
  }

  100% {
    background-color: #0c4da2;
  }
}

span.language-toggle {
  margin: 0 15px;
  border-radius: 2px;
  background-color: #2b75cc;
  padding: 3px;
  font-size: 15px;
}

span.language-toggle a {
  color: #f6faff;
  padding: 2px;
  text-decoration: none;
}

.language-toggle .active {
  border-radius: 2px;
  color: #0a50a3;
  background-color: #fff;
}

.top-right-wrapper {
  position: relative;
}

.top-right-wrapper button {
  position: absolute;
  left: 0;
  background: transparent;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

.top-right-wrapper input {
  padding-left: 30px;
  background-color: #2b75cc;
  border: none;
  height: 32px;
  border-radius: 4px;
}

.top-right-wrapper input::placeholder {
  color: #fff;
}

.home-img {
  width: 450px;
  padding: 5px;
}

li.first-main-nav > a {
  font-size: 16px;
  color: #fff;
  border-right: 1px solid #f2f2f2;
}

li.first-main-nav:last-child > a {
  border-right: none;
}

li.first-main-nav > a span {
  color: #f2f2f2 !important;
  font-size: 12px !important;
}

.headerfull {
  //   background: #ed1c24;
  background: #24519c;
  background: #fff;

  .navtext {
    text-decoration: none;
    display: flex;
    flex-direction: column;
  }
}

.overlay.bag-img {
  padding: 1.5rem 0 !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin-top: 1.5rem;
}

.steps {
  padding: 45px 0 35px 0;
  > .container {
    background: white;
    padding-block: 1rem;
    border-radius: 5px;
  }
}

// <style> {
//     ` .ant-steps {
//         width: 100%;
//         /* Full width */
//     }

//     .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item::after {
//         height: 12px;
//         /* Thickness of the progress line */
//         background-color: #ccc;
//         /* Default color for progress line */
//     }

//     .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
//         background-color: #52c41a;
//         /* Finished step color */
//     }

//     .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
//         background-color: #1890ff;
//         /* Current step color */
//     }

//     `
// }

// </style>

// .ant-steps .ant-steps-item-container{
//     text-align: center;
// }

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background-color: #eaf0f4;
}

.bottom-footer {
  position: sticky;
  margin-bottom: 0;

  p {
    margin: 0;
  }
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #eaf0f4;
  z-index: -1;
  position: relative;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color:#F0512D;
}

.ant-steps:not(.ant-steps-vertical)
  .ant-steps-item-custom
  .ant-steps-item-icon {
  width: 60px;
  height: 60px;
  color: #2e328a;
  background: #eaf0f4;
}

.ant-steps .ant-steps-item-process.ant-steps-item-custom {
  &.ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: #F0512D;

      > .ant-steps-icon {
        color: white;
      }
    }
  }

  .ant-steps-item-icon > .ant-steps-icon {
  }
}

.ant-steps .ant-steps-item-title {
  line-height: 1.4;
}

.ant-upload-list.ant-upload-list-text {
  display: none;
}

.ant-select-selection-placeholder {
  font-size: 14px;
}

.ant-card-head {
  text-align: center;
}

// custom styling start
body {
  background-color: #f5f7f9;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  // background-image: none, url(https://bankxp.manjushreefinance.com.np/assets/bg-images/background-login-default@3x.jpg);
  background-repeat: round;
  background-size: cover;
  background-attachment: fixed;
}

.btn-warning {
  color: #ffffff;
  background-color: #ce3a19;

  &:hover {
    color: #ffffff;
    background-color: #d86147;
  }
}

.btn-warning-outline {
  border: 1px solid #d86147;
  color: #d86147;
  background-color: transparent;

  &:hover {
    border: 1px solid #f0c4ba;
    color: #d86147;
    background-color: transparent;
  }
}

a {
  text-decoration: none;
}

.services {
  --aspect-ratio: (16/9);
  margin-bottom: 16px;
  border: 1px solid #dbdcdf;
  border-radius: 3px;
  width: 100%;
  transition: 0.2s ease-in-out;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
  display: flex;
  flex-direction: column;
  &:hover {
    transform: translateY(-5px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
  }
}

.services--secondary,
.services--tertiary {
  max-width: 100%;
  border-color: #dbdcdf;
  margin: 0;
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.1);
}

.services--secondary {
  --aspect-ratio: (4/3);
  height: 100%;
}

.aspect-ratio-box {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc((1 / var(--aspect-ratio, 1)) * 100%);
  overflow: hidden;
  display: block;
  object-fit: cover;
}

.aspect-ratio-box__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect-ratio-box__media--contain-fit {
  -o-object-fit: contain;
  object-fit: contain;
}

.aspect-ratio-box__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.services__media {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.services__content {
  padding-top: 16px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
  color: #191b22;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.services__top {
  flex-grow: 1;
}

// .services__content--spacing {
//     padding-bottom: 24px;
// }

.services__title {
  font-size: 18px;
  line-height: 1.33;
  font-weight: 700;
  font-family: "Mukta", sans-serif;
  margin-bottom: 28px;
  position: relative;
  color: #191b22;
}

.services__title:after {
  content: "";
  position: absolute;
  width: 33px;
  height: 5px;
  background-color: #5074b0;
  bottom: -16px;
  left: 0;
}

.services__desc {
  font-size: 14px;
  line-height: 20px;
  font-family: "OpenSans", sans-serif;
  margin-bottom: 0;
}

// .table-widget-wrapper {
//     position: fixed;
//     top: 300px;
//     right: 50px; /* Move it further right to separate it from the form */
//     padding: 20px;
//     background: #fff;
//     min-width: 400px;
//     border-radius: 10px;
//     z-index: 1000; /* Keep it above other elements */
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// }

// @media (max-width: 1900px) {
//     .table-widget-wrapper {
//         top: 20px;
//         position: static;
//         right: 20px;
//         bottom: unset;
//         min-width: 350px;
//         margin-top: 500;
//     }
// }

// @media (max-width: 768px) {
//     .table-widget-wrapper {
//     //    margin-top: 4000px;
//     top: 200px;
//     left: 5;
//         position: static; /* Allow it to flow normally on small screens */
//         margin: 10px auto; /* Center it on smaller devices */
//         width: 100%; /* Occupy full width */
//     }
// }
